<template>
    <div class="home">
      <div class="pt20"></div>
     <el-form :inline="true" :model="formInline" class="demo-form-inline">
           
            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="formInline.order_no" style="width:400px" placeholder="订单号"></el-input>
            <el-button  type="primary"  style="margin-left:20px" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
             <el-button  type="info"  style="position: absolute;right: 45px;" @click="exportlist">导出</el-button>
      </el-form> 
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
        @selection-change="handleSelectionChange"
         >
       <el-table-column type="selection" width="55" align="center"/>
       <el-table-column
                  prop="create_time"
                  width='200'
                  label="记录日期">
                <template  slot-scope="scope">
            {{scope.row.create_time | formatDate2}}    </template>
                </el-table-column>
                <el-table-column
                  prop="order_no"
                  width='220'
                  label="订单编号">
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="姓名">
                </el-table-column>
                <el-table-column
                  prop="phone"
                  label="注册手机">
                </el-table-column>
                <el-table-column
                  prop="actions.name"
                  label="行为记录">
                </el-table-column>
                <el-table-column
                  prop="specific_situations.name"
                  label="具体情况">
                </el-table-column>
                <el-table-column
                  prop="specific_doubt.name"
                  label="具体存疑点">
                </el-table-column>
                <el-table-column
                  prop="specific_materials_text"
                  label="具体材料">
                </el-table-column>
                <el-table-column
                  prop="remark"
                  label="备注">
                </el-table-column>
                <el-table-column
                  width='150'
                  label="操作">
                <template  slot-scope="scope">
                  <el-button @click='editaclog(scope.row)' size="mini" type="info" plain>编辑</el-button>
                  <el-button @click='delaclog(scope.row.id)' size="mini" type="info" plain>删除</el-button>
                </template>
                </el-table-column>
      </el-table>

      <div class="pt20"></div>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="current_page"
          :page-size="per_page"
          layout="total,prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        
      <el-dialog
        title="编辑"
        :visible.sync="editactionlistshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-form ref="logform" :model="logform" label-width="120px">
            <el-form-item label="行为记录:" prop="remark">
              <el-select v-model="logform.actionlogtype1" @change="actionlogtypeson(logform.actionlogtype1,1)" placeholder="请选择">
                <el-option
                  v-for="item in actionlogtypelist"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="具体情况:" prop="remark">
              <el-select v-model="logform.actionlogtype2" @change="actionlogtypeson(logform.actionlogtype2,2)" placeholder="请选择">
                <el-option
                  v-for="item in actionlogtypelist2"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="具体存疑点:" prop="remark">
              <el-select v-model="logform.actionlogtype3" @change="actionlogtypeson(logform.actionlogtype3,3)" placeholder="请选择">
                <el-option
                  v-for="item in actionlogtypelist3"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="具体材料:" prop="remark">
              <el-select style="width:500px;"
              multiple
              v-model="logform.actionlogtype4" placeholder="请选择">
                <el-option
                  v-for="item in actionlogtypelist4"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:" prop="remark">
              <el-input v-model.trim="logform.remark" placeholder="请输入" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="editsubmitactionlog">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src   
import axios from 'axios'
import config from '../../lib/config'
import * as XLSX from 'xlsx';

export default {
  name: 'Home',
  data(){
    return {
      loading:true,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      actionlogtypelist:[],
      actionlogtypelist2:[],
      actionlogtypelist3:[],
      actionlogtypelist4:[],
      payChannel:[],//支付渠道
      formInline: {
         order_no:'',//检索内容
      },
      status:'',//支付渠道
      timevalue:'',//交易日期
      // 选中数组
      ghs: [],
      nums:[],
      logform:{
        actionlogtype1:'',
        actionlogtype2:'',
        actionlogtype3:'',
        actionlogtype4:'',
      },
     //选中的记录数量
     initdata:{
         order_no:'',//检索内容
     },
     selectedNum:0,
      editactionlistshow:false,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  filters: {
    formatDate2(timestamp) {
      const date = new Date(timestamp*1000);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hour = ('0' + date.getHours()).slice(-2);
      const minute = ('0' + date.getMinutes()).slice(-2);
      const second = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
  },
  methods: {
      exportlist(){
        const jsonData = [
        ["记录日期", "订单编号", "姓名", "注册手机", "行为记录", "具体情况", "具体存疑点", "具体材料", "备注"]
        ];
        let tData = this.tableData;
            for(let i in tData) {
              let arr = [];
              arr.push(create_time);
              arr.push(tData[i]['order_no']);
              arr.push(tData[i]['name']);
              arr.push(tData[i]['phone']);
              arr.push(tData[i]['actions'] ? tData[i]['actions'].name : '');
              arr.push(tData[i]['specific_situations'] ? tData[i]['specific_situations'].name : '');
              arr.push(tData[i]['specific_doubt'] ? tData[i]['specific_doubt'].name : '');
              arr.push(tData[i]['specific_materials_text']);
              arr.push(tData[i]['remark']);
              let create_time = ctime(tData[i]['create_time']);
              jsonData.push(arr);
            }
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet(jsonData);
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            let date = new Date();
            let tt = date.getFullYear() +'-'+ ('0' + (date.getMonth() + 1)).slice(-2) +'-'+ ('0' + date.getDate()).slice(-2);
            XLSX.writeFile(wb, `限时行为统计 ${tt}.xlsx`);
            function ctime(timestamp){
              const date = new Date(timestamp*1000);
              const year = date.getFullYear();
              const month = ('0' + (date.getMonth() + 1)).slice(-2);
              const day = ('0' + date.getDate()).slice(-2);
              const hour = ('0' + date.getHours()).slice(-2);
              const minute = ('0' + date.getMinutes()).slice(-2);
              const second = ('0' + date.getSeconds()).slice(-2);
              return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
            }
      },
      editsubmitactionlog() {
        let _this = this;
        console.log(this.logform)
        if (!this.logform.actionlogtype1) {
          _this.$message.error('表单数据不能为空');
          return false;
        }
        this.logform.order_no = _this.topOrder_no;
        console.log(_this.logform.actionlogtype4.toString())
        let data = {
          id:_this.logform.id,
          action:_this.logform.actionlogtype1 ? _this.logform.actionlogtype1 : 0,
          specific_situation:_this.logform.actionlogtype2 ? _this.logform.actionlogtype2 : 0,
          specific_doubts:_this.logform.actionlogtype3 ? _this.logform.actionlogtype3 : 0,
          specific_materials:_this.logform.actionlogtype4 ? _this.logform.actionlogtype4.toString() : '',
          remark:_this.logform.remark ? _this.logform.remark : '',
        };
        this.$refs.logform.validate(valid => {
          if (valid) {
            axios.post(config.action_log,data)
            .then(function (response) {
                if(response.data.code == 200){
                  _this.$message({
                    message: response.data.message,
                    type: 'success'
                  });
                  _this.handleSelect('10');
                  _this.editactionlistshow = false;
                }else{
                  _this.$message.error(response.data.message);
                }
              })
            .catch(function (error) {
              console.log(error);
            });
          } else {
            _this.$message.error('表单验证失败');
            return false;
          }
        });
      },
      actionlogtypeson(pid,type,boo){
        let _this = this;
        axios.get(config.selectaction_log_type,{params:{pid:pid}})
        .then(function (response) {
              if(response.data.code == 200){
                if(type == 1){
                  _this.actionlogtypelist2 = response.data.data;
                  if(!boo){
                    _this.actionlogtypelist3 = [];
                    _this.actionlogtypelist4 = [];
                    _this.logform.actionlogtype2 = '';
                    _this.logform.actionlogtype3 = '';
                    _this.logform.actionlogtype4 = '';
                  }
                }  
                if(type == 2){
                  _this.actionlogtypelist3 = response.data.data;
                  if(!boo){
                    _this.actionlogtypelist4 = [];
                    _this.logform.actionlogtype3 = '';
                    _this.logform.actionlogtype4 = '';
                  }
                }
                if(type == 3){
                  _this.actionlogtypelist4 = response.data.data;
                  if(!boo){
                    _this.logform.actionlogtype4 = '';
                  }
                }
              }else{
                _this.$message.error(response.data.message);
              }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
    editaclog(data){
      let _this = this;
      _this.editactionlistshow = true;
      if(data.action){
        _this.actionlogtypeson(data.action,1,true)
      }
      if(data.specific_situation){
        _this.actionlogtypeson(data.specific_situation,2,true)
      }
      if(data.specific_doubts){
        _this.actionlogtypeson(data.specific_doubts,3,true)
      }
        _this.logform = {
          id:data.id,
          actionlogtype1:data.action ? data.action*1 : '',
          actionlogtype2:data.specific_situation*1 ? data.specific_situation*1 : '',
          actionlogtype3:data.specific_doubts*1 ? data.specific_doubts*1 : '',
          actionlogtype4:data.specific_materials ? data.specific_materials.split(',') : '',
          remark:data.remark,
        };
        if(_this.logform.actionlogtype4){
          for(let i in _this.logform.actionlogtype4){
            _this.logform.actionlogtype4[i] = _this.logform.actionlogtype4[i] ? _this.logform.actionlogtype4[i] * 1 : _this.logform.actionlogtype4[i];
          }
        }
    },
    delaclog(id){
      let _this = this;
        axios.delete(config.action_log,{data:{id:id}})
        .then(response => {
          if(response.data.code == 200){
            _this.$message({
              message: response.data.message,
              type: 'success'
            });
            _this.onSubmit('CHA');
          }else{
              _this.$message.error(response.data.message);
            }
        })
        .catch(error => {
          _this.$message.error(error);
        })
    },
      onSubmit(CHA){
        let _this = this;
        _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
        axios.get(config.action_list,{params:_this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      handleSelect(key) {
        this.formInline.order_type = key;
        this.onSubmit('CHA');
      },
      setpage(page){
        this.total = page.total;
        this.per_page = page.per_page;
        this.current_page = page.current_page;
      },typeIndex(index) {
        let _this = this; //处理分页数据的 index
        return (_this.formInline.page - 1) * 20 + index + 1;
      },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.id)
        this.nums = selection.map(item => item.num)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
      },
    init(){
        let _this = this;
        axios.get(config.action_list,{params:this.initdata})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        
        axios.get(config.selectaction_log_type,{params:{pid:0}})
        .then(function (response) {
              if(response.data.code == 200){
               _this.actionlogtypelist = response.data.data;
              }else{
                _this.$message.error(response.data.message);
              }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 70px;
    text-align: left;
}
</style>